/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-01-02",
    versionChannel: "nightly",
    buildDate: "2024-01-02T00:14:35.776Z",
    commitHash: "c47962fc710a4397c21856b8bcbed88274776fb3",
};
